import React from 'react'
import { Heading, Grid, Spinner } from '@enterprise-ui/canvas-ui-react'
import BasePage from 'components/pages/BasePage/BasePage'
import AssetFeed from 'components/feeds/AssetFeed/AssetFeed'
import { cleanTitle, capitalize } from 'utils/string'
import './SubcategoryLandingPage.scss'
import Carousel from 'components/Carousel/Carousel'
import { useSubcategory } from 'hooks'
import { ZeroStateToShowEnum } from 'store'
import { numberWithCommas } from 'utils/number'
import { useParams } from 'react-router-dom'

const SubcategoryLandingPage = () => {
  const { name } = useParams()
  const { totalCount, groups, assets, infiniteScrollMethod, assetsAreLoading } = useSubcategory(name!)

  return (
    <BasePage>
      <Grid.Container direction="column">
        <Grid.Item xs={12} className="hc-pb-none">
          <Grid.Container justify="space-between" direction="column" className="subcategory-page-centered-content">
            <Grid.Item className="hc-ph-min hc-pb-lg">
              <Heading data-testid="subcategory-name" className="hc-mb-dense" size={2}>
                {capitalize(cleanTitle(name!))}
              </Heading>

              <p data-testid="asset-count">
                Number of assets:{' '}
                <span data-testid="subcategory-asset-count" style={{ fontWeight: 'bold' }}>
                  {totalCount ? numberWithCommas(totalCount) : 0}
                </span>
              </p>
            </Grid.Item>
            {!groups && (
              <Grid.Item style={{ width: 'inherit' }} className="hc-pr-xl">
                <Grid.Container justify="center" className="hc-pt-xl">
                  <Spinner data-test-id="load-indicator" />
                </Grid.Container>
              </Grid.Item>
            )}
            {!!groups?.length && (
              <Grid.Item style={{ width: 'inherit' }} className="hc-pr-xl">
                <Grid.Container direction="column">
                  <Heading data-test-id="collection-name" className="hc-pv-normal hc-ph-min" size={5}>
                    Types
                  </Heading>
                  <Grid.Item style={{ width: 'inherit' }} className="hc-pr-2x">
                    {groups.length >= 1 && <Carousel subCategories={groups} />}
                  </Grid.Item>
                </Grid.Container>
              </Grid.Item>
            )}
          </Grid.Container>
        </Grid.Item>
        <Grid.Item xs={12} className="hc-pt-xl">
          <AssetFeed
            pageName={capitalize(cleanTitle(name!))}
            isLoading={assetsAreLoading}
            assets={assets}
            totalCount={totalCount}
            infiniteScrollMethod={infiniteScrollMethod}
            showAssetsLabel={true}
            zeroStateToShow={ZeroStateToShowEnum.SUBCATEGORYPAGE}
          />
        </Grid.Item>
      </Grid.Container>
    </BasePage>
  )
}

export default SubcategoryLandingPage
